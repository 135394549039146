import axios from 'axios'
import { error } from 'jquery'
import Cookie from 'js-cookie'

// Base URL for the API
const BASE_URL = 'https://swipetoshare.com/api/'
export const TOKEN = localStorage.getItem('accessToken')
export const REFRESH_TOKEN = '2MWBpodhb-1yf63qB8iOrkykrsuP3G98KAqkbV0fwwo'
// API endpoints
export const ENDPOINTS = {
  getPermissionList: 'permissions/list',
  getAllPermissions: '/getAllPermissions',
  createPermission: '/permissions/create',
  getpermissionsbyid: '/getpermissionsbyid',
  updatePermission: '/permissions/update',
  deletePermission: '/permissions/delete',

  //Channel Adivsor Token
  channelAdivsorTokenRefresh: 'channeladvisor/oauth2/token',

  //Sandbox warehouse Api
  sandBoxWarehouseCreate: 'sandbox/warehouse/create',
  sandBoxUpdateWarehouseDetail: 'sandbox/warehouse/update',
  sandBoxDeleteWareHouse: 'sandbox/warehouse/delete',
  sandBoxGetWarehouses: 'sandbox/warehouse/list',
  sandBoxGetWarehouseByID: 'sandbox/getWarehouseByID',
  sandBoxDeleteMultipleWareHouse: 'sandbox/warehouse/multiple_warehouse_delete',

  //SandBox Product Api
  sandBoxUploadProducts: 'sandbox/upload-products',
  sandBoxGetProductbySupplier: 'sandbox/getProductbySupplier',
  sandBoxGetProductbySku: 'sandbox/getProductbySku',
  sandBoxGetProductFileList: 'sandbox/product-files-list',
  sandBoxGetProducts: 'sandbox/product/list',
  sandBoxDeleteProducts: 'sandbox/product/delete',

  sandBoxProductUpdate: 'sandbox/product/productdetailsupdate',
  sandBoxProductimageupdate: 'sandbox/product/productimageupdate',
  sandBoxProductAttributeUpdate: 'sandbox/product/productAttributeUpdate',
  sandBoxProductVideoUpdate: 'sandbox/product/productVideoUpdate',
  sandBoxProductPdfupdate: 'sandbox/product/ProductPdfupdate',
  sandboxGetProductID: 'sandbox/getProductID',

  //SandBox Image api
  sandBoxUploadProfileStepImages: 'sandbox/profile/upload-image',
  sandBoxGetImageBySupplierId: 'sandbox/profile/get-all-images',

  //Warehouse List
  sandBoxGetWareHouseInventory: 'sandbox/warehouse-inventory/list',
  sandBoxGetWareHouseInventoryListById:
    'sandbox/warehouse-inventory/GetInventoryListById',
  sandBoxGetProfileWareHouseInventoryListById:
    'sandbox/profile/GetInventoryListById',
  sandBoxDownloadProfileWarehouseInventory:
    'sandbox/profile/downlaod-warehouse-inventory',
  sandBoxUploadProfileWareHouseInventory:
    'sandbox/profile/upload-warehouse-inventory',
  sandBoxUpdateProfileInventoryQuantityById:
    'sandbox/profile/UpdateInventoryQuantityById',
  sandBoxUpdateWarehouseInventoryQuantity:
    'sandbox/warehouse-inventory/UpdateProductQuantity',
  sandBoxUpdateMultipleWarehouseQuantity:
    'sandbox/warehouse-inventory/UpdateMultipleWarehouseQuantity',

  //login
  login: '/login',
  verifyEmail: '/verify-email',
  loginAsSupplier: '/suppliers/login-as-suppliers',
  stopImpersonation: 'suppliers/stop-impersonation',
  emailverificationCode: 'send-code',
  codeVerify: 'verify-code',
  register: 'register',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',

  //SuperAdmin
  getSuperAdminDetail: 'superadmin/view',
  getAdminDashboardDetail: 'superadmin/dashboard',

  //roles
  getRoles: '/roles/list',
  createRoles: '/roles/create',
  updateRoles: '/roles/update',
  getRolesByID: '/getRolesByID',
  deleteRoles: '/roles/delete',

  //brands
  getBrands: '/brand/list',
  createBrands: '/brand/create',
  updateBrands: '/brand/update',
  getBrandsByID: '/getBrandsByID',
  deleteBrands: '/brand/delete',
  addBrand: 'admin/suppliers/add_brand',

  //Category
  getCategory: '/categories/list',
  createCategory: '/categories/create',
  updateCategory: '/categories/update',
  getCategoryByID: '/getCategoryByID',
  deleteCategory: '/categories/delete',
  updateCategoryStatusByID: '/updateCategoryStatusByID',

  //Country
  getCountry: 'country/list',
  getCountryByID: 'getCountryByID',
  createCountry: '/country/create',
  updateCountry: 'country/update',
  deleteCountry: 'country/delete',

  getTerritory: '/territory/list',
  getTerritoryById: 'territory/getTerritoryByID',
  createTerritory: '/territory/create',
  updateTerritory: '/territory/update',
  deleteTerritory: '/territory/delete',

  //State
  getState: '/state/list',
  createState: '/state/create',
  updateState: '/state/update',
  getStateByID: '/getStateByID',
  deleteState: '/state/delete',
  getStateBycountryID: 'getStateBycountryID',

  //City
  getCity: '/city/list',
  createCity: '/city/create',
  updateCity: '/city/update',
  getCityID: '/getCityID',
  deleteCity: '/city/delete',

  //Promotions
  getListOfPromotions: 'promotions/list',
  createPromotion: 'promotions/create',
  getPromotionByID: 'promotions/getPromotionByID',
  updatePromotion: 'promotions/update',
  deletePromotion: 'promotions/delete',

  //MarketPlace-Inventory
  getMarketPlaceInventoryList: 'marketplace-inventory/list',
  deleteMarketplaceInventory: 'marketplace-inventory/delete',
  getMarketplaceInventoryByID:
    'marketplace-inventory/getMarketplaceInventoryByID',

  //Geolocation API
  geoLocation: 'integrations/getGeocode',

  //Currency Converter API
  currencyRateConverter: 'integrations/currencyRateConverter',

  //Pronorm Agent
  proAgentRegister: 'proagentregister',
  proAgentList: 'proagent/list',
  deleteProAgent: 'proagent/delete',
  proNormSupplierList: 'proagent/supplierslist',

  //Integrations
  getListOfIntegrations: 'integrations/list',
  deleteIntegration: 'integrations/delete',
  getIntegrationsByID: 'integrations/getIntegrationsByID',
  createIntegration: 'integrations/create',
  updateIntegration: 'integrations/update',

  //Suppliers

  getSupplierByStatus: '/getSupplierByStatus',
  addSupplier: 'suppliers/create',
  deleteSupplier: 'suppliers/delete',
  getSupplierById: 'getSupplierID',
  updateSupplierDetail: 'suppliers/update',
  addAdminSupplierDeail: 'admin/suppliers/create',
  updateAdminSupplierDetail: 'admin/suppliers/update',

  //SuperAdmin
  getSuperAdminList: 'superadmin/list',
  getSuperAdminById: 'getSuperadminID',
  deleteSuperAdmin: 'superadmin/delete',
  addSuperAdmin: 'superadmin/create',
  updateSuperAdminDetail: 'superadmin/update',

  //Affiliate
  getAffiliateList: 'affiliate/list',
  getAffiliateById: 'getAffiliateID',
  deleteAffiliate: 'affiliate/delete',
  addAffiliate: 'affiliate/create',
  updateAffiliate: 'affiliate/update',

  //Ticket-Category
  getTicketCategory: 'ticket-categories/list',
  deleteTicketCategory: 'ticket-categories/delete',
  getTicketCategoryByID: 'getTicketCategoryByID',
  addTicketCategory: 'ticket-categories/create',
  updateTicketCategory: 'ticket-categories/update',

  //Ticket-Priority
  getTicketPriority: 'ticket-priorities/list',
  deleteTicketPriority: 'ticket-priorities/delete',
  getTicketPriorityByID: 'getTicketPriorityByID',
  addTicketPriority: 'ticket-priorities/create',
  updateTicketPriority: 'ticket-priorities/update',

  productUpdate: 'product/productdetailsupdate',
  productAttributeUpdate: 'product/productAttributeUpdate',
  productimageupdate: 'product/productimageupdate',
  productVideoUpdate: 'product/productVideoUpdate',
  ProductPdfupdate: 'product/ProductPdfupdate',

  //Sync
  syncOrders: 'sync_order',
  syncProducts: 'sync_product',
  syncWarehouse: 'sync_warehouse',

  //marketplace
  getMarketplace: '/marketplace/list',
  getMarketplaceByID: '/getMarketplaceID',
  createMarketplace: '/marketplace/create',
  updateMarketplace: '/marketplace/update',
  deleteMarketplace: '/marketplace/delete',

  //MarketPlace Inventory
  createMarketplaceInventory: 'marketplace-inventory/create',
  updateMarketplaceInventory: 'marketplace-inventory/update',

  //shippingcarriers
  getAllShippingCarriers: '/getAllShippingCarriers',
  getShippingCarriers: '/shipping-carriers/list',
  createShippingCarriers: '/shipping-carriers/create',
  updateShippingCarriers: '/shipping-carriers/update',
  deleteShippingCarriers: '/shipping-carriers/delete',
  getShippingCarriersByID: '/getShippingCarriersByID',

  //Carrier Classes
  getAllCarrierClasses: '/getAllCarrierClasses',
  getCarrierClass: '/carrier_classes/list',
  createCarrierClass: '/carrier_classes/create',
  updateCarrierClass: '/carrier_classes/update',
  deleteCarrierClass: '/carrier_classes/delete',
  getCarrierClassesByID: '/getCarrierClassesByID',

  //shippingcarriers
  getShippingServices: '/shipping-services/list',
  createShippingServices: '/shipping-services/create',
  updateShippingServices: '/shipping-services/update',
  deleteShippingServices: '/shipping-services/delete',
  getShippingServiceByID: '/getShippingServiceByID',
  getShippingServiceByCountryID: 'getShippingServiceByCountryID',

  //Fulfillment
  getFulfillment: '/fulfillment-users/list',
  createFulfillment: '/fulfillment-users/create',
  updateFulfillment: '/fulfillment-users/update',
  getFulfillmentID: '/getFulfillmentID',
  deleteFulfillment: '/fulfillment-users/delete',

  //product
  getProducts: '/product/list',
  createProducts: '/product/create',
  updateProducts: '/product/update',
  updateProductsSpecial: '/product/updateSpecial',
  deleteProducts: '/product/delete',
  getProductID: '/getProductID',
  getAllProduct: 'getAllProduct',

  //Master
  getAllCity: 'getAllCity',
  getAllState: 'getAllState',
  getAllSuppliers: 'getAllSuppliers',
  getAllBrands: 'getAllBrands',
  getAllProductStatus: 'getAllProductStatus',
  getAllCountry: 'getAllcountry',
  getAllMarketplace: 'getAllMarketplace',
  getAllMainCategories: 'getAllMainCategories',
  getAllWarehouse: 'getAllWarehouse',
  getAllTeritory: 'territory/getAllTerritory',

  //Warehouses
  getWarehouses: 'warehouse/list',

  //Supplier Panel Api Call

  supplierProfileUpdate: 'suppliers/profile/update_profile',

  //Supplier StepForm Api Call
  companyProfile: 'suppliers/profile/company_profile',
  getUserProfileById: 'getUserProfileById',
  uploadProducts: 'suppliers/profile/upload-products',
  //Product
  getProductbySupplier: 'getProductbySupplier',
  getProductbySku: 'getProductbySku',
  // getSuppliersProductList: 'suppliers/product-list',
  // supplierProductUpload: 'suppliers/upload-products',
  // supplierImageUpload: 'suppliers/upload-image',
  // downloadProductFile: 'suppliers/downlaod-product',
  // getAllImages: 'suppliers/get-all-images',
  getAllProductFileList: 'suppliers/product-files-list',
  getProductFileList: 'suppliers/profile/product-files-list',
  getAllProductExcel: 'suppliers/download-suppliers-products',
  // deleteImage: 'suppliers/delete-image',
  // deleteProfileProductFile: 'suppliers/profile/delete-products-by-file',
  deleteProfileMultipleProductFile: 'suppliers/profile/product-multiple-delete',

  //for supplier
  downloadProfileProductFile: 'suppliers/profile/downlaod-product',
  getImageBySupplierId: 'suppliers/profile/get-all-images',
  uploadProfileStepImages: 'suppliers/profile/upload-image',
  deleteProfileStepImages: 'suppliers/profile/delete-image',
  // getSuppliersProfileProductList: 'suppliers/profile/product-list',
  getSupplierProfileStatusById: 'getSupplierProfileStatusById',
  supplierDashboardDetail: 'suppliers/dashboard',
  getSupplierLatestOrder: 'suppliers/latestorder',
  getUnshippedordercount: 'suppliers/unshippedordercount',

  //Currency
  getAllCurrency: 'getAllCurrency',

  //WareHouse
  createWareHouse: 'warehouse/create',
  deleteWareHouse: 'warehouse/delete',
  getWarehouseID: 'getWarehouseID',
  updateWarehouseDetail: 'warehouse/update',
  deleteMultipleWareHouse: 'warehouse/multiple_delete',

  ///Retailer and Teritory
  sendTeritoryMail: 'send-territory-email',
  getMarketplacesByTerritory: 'marketplace/getMarketplacesByTerritory',

  addProfileWarehouse: 'suppliers/profile/warehouse-create',
  profileWarehouseList: 'suppliers/profile/warehouse-list',
  profilegetWarehouseID: 'suppliers/profile/getWarehouseID',
  editProfileWarehouse: 'suppliers/profile/warehouse-update',

  //warehouse Inventory
  getWareHouseInventory: 'warehouse-inventory/list',
  getWareHouseInventoryListById: 'warehouse-inventory/GetInventoryListById',

  deleteWareHouseInventory: 'warehouse-inventory/delete',
  downloadProfileWarehouseInventory:
    'suppliers/profile/downlaod-warehouse-inventory',
  uploadProfileWareHouseInventory:
    'suppliers/profile/upload-warehouse-inventory',
  getProfileWareHouseInventoryListById:
    'suppliers/profile/GetInventoryListById',
  updateProfileInventoryQuantityById:
    'suppliers/profile/UpdateInventoryQuantityById',
  updateWarehouseInventoryQuantity: 'warehouse-inventory/UpdateProductQuantity',
  updateMultipleWarehouseQuantity:
    'warehouse-inventory/UpdateMultipleWarehouseQuantity',

  //Support
  supportTicketsList: 'support_tickets/list',
  supportTickets: 'support_tickets/getSupportTicketBySupplierID',
  getTicketDetailById: 'support_tickets/getSupportTicketByID',
  createSupportTicket: 'support_tickets/create',
  updateSupportTicket: 'support_tickets/update',
  deleteSupportTicket: 'support_tickets/delete',
  changeSupportTicketStatus: 'support_tickets/changeStatus',
  ticketResponse: 'support_tickets/ticket_response',
  getAllTicketResponsesById: 'getAllTicketResponsesById',
  //Order
  orderList: 'orders/list',
  getOrderCancelReasons: 'orders/getOrderCancelReasons',
  cancelOrder: 'orders/cancelorder',
  markOrderPendingShipment: 'orders/markorderpendingshipment',
  markOrderShipped: 'orders/markordershipped',

  rithumShippingRates: 'orders/shippingrates',
  rithumPurchaseLabel: 'orders/PurchaseLabel',

  //Rithum Api Call

  getRithumProducts: '/Products',
  rithumProductUpload: 'rithum/ProductUpload',
  rithumOrderDetails: 'Orders',
  getErrorRithumProductUpload: 'token/ProductUpload',
  rithumFulfillments: 'Fulfillments',
  rithumProductUploadError: 'token/ProductUploaderrorbytoken',
  rithumDistributionCenter: 'DistributionCenters',
  rithumProductImages: 'Images',

  //Pebblely api
  getTheme: 'fetch-themes',
  createBackground: 'create-background',
}

// Create an Axios instance
// const apiInstance = axios.create({
//   baseURL: BASE_URL,
//   timeout: 10000,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// })
// apiInstance.interceptors.request.use(
//   config => {
//     const token = Cookie.get('userToken')
//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`
//     }
//     return config
//   },
//   error => {
//     // Handle the error
//     return Promise.reject(error)
//   },
// )

// export default apiInstance
